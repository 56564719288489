import hasRole from './permission/hasRole'
import hasPermi from './permission/hasPermi'
import copyText from './common/copyText'
import thousands from './common/thousands'
import thousandsPrice from './common/thousandsPrice'
import integer from './common/integer'
import percent from './common/percent'
import percentInteger from './common/percentInteger'

export default function directive(app){
  app.directive('hasRole', hasRole)
  app.directive('hasPermi', hasPermi)
  app.directive('copyText', copyText)
  app.directive('thousands', thousands)
  app.directive('thousandsPrice', thousandsPrice)
  app.directive('percent', percent)
  app.directive('integer', integer)
  app.directive('percentInteger', percentInteger)
}