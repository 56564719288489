import useUserStore from '@/store/modules/user'
export class SentryEvent {
  constructor(message, level, tags, extra, breadcrumbs) {
    this.message = message || 'sentry_event'; // 消息标题
    this.level = level || 'info'; // 消息等级
    this.tags = tags || {}; // 事件的标签
    this.extra = extra || {}; // 额外的信息 可以加入一些想在报错时捕获的信息，用来传输 error 当 error是一个对象时，如果标题直接传 error 消息标题会显示[object Object]
    this.breadcrumbs = breadcrumbs || []; // 面包屑 报错时的上下文
    this.user = {userName:useUserStore().userName,userId:useUserStore().userId}; // 用户信息
  }
}
