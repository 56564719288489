import { createWebHistory, createRouter } from "vue-router";
/* Layout */
import Layout from "@/layout";
import AppMain from "@/layout/components/AppMain.vue";

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
 noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
 title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
 icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
 breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
 activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
 }
 */

// 公共路由
export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/redirect/index.vue")
      }
    ]
  },
  {
    path: "/login",
    component: () => import("@/views/login.vue"),
    hidden: true
  },
  // {
  //   path: '/register',
  //   component: () => import('@/views/register.vue'),
  //   hidden: true
  // },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/error/404.vue"),
    hidden: true
  },
  {
    path: "/401",
    component: () => import("@/views/error/401.vue"),
    hidden: true
  },
  {
    path: "/user",
    component: Layout,
    hidden: true,
    redirect: "noredirect",
    children: [
      {
        path: "profile",
        component: () => import("@/views/system/user/profile/index.vue"),
        name: "Profile",
        meta: { title: "个人中心", icon: "user" }
      }
    ]
  },
  {
    path: "",
    component: Layout,
    redirect: "/index",
    children: [
      {
        path: "index",
        component: () => import("@/views/Huangshan/auditConfig/index.vue"),
        name: "index",
        meta: { title: "稽核配置", icon: "dashboard", affix: true }
      }
    ]
  }
];

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [
  {
    path: "/system/user-auth",
    component: Layout,
    hidden: true,
    permissions: ["system:user:edit"],
    children: [
      {
        path: "role/:userId(\\d+)",
        component: () => import("@/views/system/user/authRole.vue"),
        name: "AuthRole",
        meta: { title: "分配角色", activeMenu: "/system/user" },
      },
    ],
  },
  {
    path: "/system/role-auth",
    component: Layout,
    hidden: true,
    permissions: ["system:role:edit"],
    children: [
      {
        path: "user/:roleId(\\d+)",
        component: () => import("@/views/system/role/authUser.vue"),
        name: "AuthUser",
        meta: { title: "分配用户", activeMenu: "/system/role" },
      },
    ],
  },
  {
    path: "/system/dict-data",
    component: Layout,
    hidden: true,
    permissions: ["system:dict:list"],
    children: [
      {
        path: "index/:dictId(\\d+)",
        component: () => import("@/views/system/dict/data.vue"),
        name: "Data",
        meta: { title: "字典数据", activeMenu: "/system/dict" },
      },
    ],
  },
  {
    path: "/system/monitor/job-log",
    component: Layout,
    hidden: true,
    permissions: ["monitor:job:list"],
    children: [
      {
        path: "index/:jobId(\\d+)",
        component: () => import("@/views/monitor/job/log.vue"),
        name: "JobLog",
        meta: { title: "调度日志", activeMenu: "/system/monitor/job" },
      },
    ],
  },
  {
    path: "/tool/gen-edit",
    component: Layout,
    hidden: true,
    permissions: ["tool:gen:edit"],
    children: [
      {
        path: "index/:tableId(\\d+)",
        component: () => import("@/views/tool/gen/editTable.vue"),
        name: "GenEdit",
        meta: { title: "修改生成配置", activeMenu: "/tool/gen" },
      },
    ],
  },
  {
    path: "/audit/new",
    component: Layout,
    hidden: true,
    permissions: ["newAudit:new"],
    children: [
      {
        path: "/newAudit",
        component: () => import("@/views/newAudit/index.vue"),
        name: "newAudit",
        meta: { title: "创建稽核", affix: true },
      },
    ],
  },
  {
    path: "/audit/edit",
    component: Layout,
    hidden: true,
    permissions: ["newAudit:edit"],
    children: [
      {
        path: "/editAudit/:id",
        component: () => import("@/views/newAudit/index.vue"),
        name: "editAudit",
        props: true,
        meta: { title: "编辑稽核", icon: "dashboard", affix: true },
      },
    ],
  },
  {
    path: "/audit/new-excel",
    component: Layout,
    hidden: true,
    permissions: ["excel:new"],
    children: [
      {
        path: "/excel",
        component: () => import("@/views/newAudit/excelConfig.vue"),
        name: "excel",
        meta: { title: "创建Excel稽核", affix: true },
      },
    ],
  },
  {
    path: "/auditResult",
    component: Layout,
    hidden: true,
    permissions: ["auditResult:edit"],
    children: [
      {
        path: "/auditResult/:id",
        component: () => import("@/views/auditResult/index.vue"),
        name: "auditResult",
        props: true,
        meta: { title: "查看稽核结果", icon: "dashboard", affix: true },
      },
    ],
  },
  {
    path: "/endUser/config",
    component: Layout,
    hidden: true,
    permissions: ["endUser:config:edit"],
    children: [
      {
        path: "/endUserConfig",
        component: () => import("@/views/endUser/index.vue"),
        name: "endUserConfig",
        props: true,
        meta: { title: "稽核结果页配置", icon: "dashboard", affix: true },
      },
    ],
  },
  {
    path: "/Huangshan/adjust",
    component: Layout,
    hidden: true,
    permissions: ["Huangshan:adjust"],
    children: [
      {
        path: "/diffAdjust",
        component: () =>
          import("@/views/bill/adjustment/differential/diffAdjust.vue"),
        name: "diffAdjust",
        props: true,
        meta: { title: "差异调整", affix: false },
      },
      {
        path: "/diffAdjustOrder",
        component: () =>
          import("@/views/bill/adjustment/differential/diffAdjustOrder.vue"),
        name: "diffAdjustOrder",
        props: true,
        meta: { title: "差异订单", affix: false },
      },
      {
        path: "/showRDiff",
        component: () =>
            import("@/views/bill/adjustment/differential/showRDiff.vue"),
        name: "showRDiff",
        props: true,
        meta: { title: "结算差异", affix: false },
      },
    ],
  },
];

export const simpleRoutes = [
  {
    path: "/simple",
    component: AppMain,
    redirect: "/simple/index",
    children: [
      {
        path: "/simple/index",
        component: () => import("@/views/home/index.vue"),
        name: "SimpleIndex",
        meta: { title: "首页", icon: "dashboard", affix: true },
      },
      {
        path: "PEHomePage",
        component: () => import("@/views/PEHomePage/index.vue"),
        name: "SimplePEHomePage",
        meta: { title: "稽核配置", icon: "dashboard", affix: true },
      },
      {
        path: "Huangshan",
        component: () => import("@/views/Huangshan/home/index.vue"),
        name: "SimpleHuangshan",
        meta: { title: "黄山旅游", icon: "dashboard", affix: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.onError((error, to) => {
  console.log('to',to)
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location = to.fullPath
  }
})

export default router;
