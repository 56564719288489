import {createApp} from 'vue'

// import * as Cookies from 'js-cookie'

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn' // 中文语言

import '@/assets/styles/index.scss' // global css

import App from './App.vue'
import store from './store'
import router from './router'
import directive from './directive' // directive

// 注册指令
import plugins from './plugins' // plugins
import {download} from '@/utils/request'

// svg图标
import 'virtual:svg-icons-register'
import SvgIcon from '@/components/SvgIcon/index.vue'
import elementIcons from '@/components/SvgIcon/svgicon'

import './permission' // permission control

import {useDict} from '@/utils/dict'
import {parseTime, resetForm, addDateRange, handleTree, selectDictLabel, selectDictLabels , getThousandth, formatPercent } from '@/utils/ruoyi'

import { div , add , mul , sub , equal } from '@/utils/number'

// 分页组件
import Pagination from '@/components/Pagination/index.vue'
// 自定义表格工具组件
import RightToolbar from '@/components/RightToolbar/index.vue'
// 富文本组件
import Editor from "@/components/Editor/index.vue"
// 文件上传组件
import FileUpload from "@/components/FileUpload/index.vue"
// 图片上传组件
import ImageUpload from "@/components/ImageUpload/index.vue"
// 图片预览组件
import ImagePreview from "@/components/ImagePreview/index.vue"
// 自定义树选择组件
import TreeSelect from '@/components/TreeSelect/index.vue'
// 字典标签组件
import DictTag from '@/components/DictTag/index.vue'

import JsonViewer from "vue3-json-viewer";
import "vue3-json-viewer/dist/index.css"; // 引入样式

import 'plugin-edit/dist/style.css'

// sentry 监控
import * as Sentry from "@sentry/vue";

const app = createApp(App)

// 全局方法挂载
app.config.globalProperties.useDict = useDict
app.config.globalProperties.download = download
app.config.globalProperties.parseTime = parseTime
app.config.globalProperties.resetForm = resetForm
app.config.globalProperties.handleTree = handleTree
app.config.globalProperties.addDateRange = addDateRange
app.config.globalProperties.selectDictLabel = selectDictLabel
app.config.globalProperties.selectDictLabels = selectDictLabels
app.config.globalProperties.getThousandth = getThousandth
app.config.globalProperties.formatPercent = formatPercent
app.config.globalProperties.div = div
app.config.globalProperties.add = add
app.config.globalProperties.mul = mul
app.config.globalProperties.sub = sub
app.config.globalProperties.equal = equal

// 全局组件挂载
app.component('DictTag', DictTag)
app.component('Pagination', Pagination)
app.component('TreeSelect', TreeSelect)
app.component('FileUpload', FileUpload)
app.component('ImageUpload', ImageUpload)
app.component('ImagePreview', ImagePreview)
app.component('RightToolbar', RightToolbar)
app.component('Editor', Editor)

if (import.meta.env.VITE_APP_ENV as any === 'sentry') {
    Sentry.init({
        app,
        dsn: "http://28ed078a55d287b83eb583f2eb005e7a@sentry.rcszh.com/6",
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration()
        ],
        tracesSampleRate: 1.0, 
        replaysSessionSampleRate: 0.1, 
        replaysOnErrorSampleRate: 1.0, 
    });
  }

app.use(router)
app.use(store)
app.use(plugins)
app.use(elementIcons)
app.use(JsonViewer)
app.component('svg-icon', SvgIcon)

directive(app)

// 使用element-plus 并且设置全局的大小
app.use(ElementPlus, {
    locale: zhCn,
    // 支持 large、default、small
    // size: Cookies.get('size') as any || 'default'
})

app.mount('#app')
