import Big from 'big.js'

// 除法
export function div(a = 0, b = 0, digit = 2) {
    if (!b) {
        return 0;
    }
    return new Big( new Big(a).div(b).toNumber().toFixed(digit) ).toNumber();
}
// 加法
export function add(a = 0, b = 0) {
    return new Big(a).plus(b).toNumber();
}
// 乘法
export function mul(a = 0, b = 0) {
    return new Big(a).times(b).toNumber();
}
// 减法
export function sub(a = 0, b = 0) {
    return new Big(a).minus(b).toNumber();
}
// 相等
export function equal(a = 0, b = 0) {
    return new Big(a).toNumber()  === new Big(b).toNumber();
}